import * as React from 'react';

function DespaceIcon({ fill, width, ...rest }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width ?? 700}
      viewBox="0 0 608.15 290.24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill={fill ?? 'white'}
        d="M57.12,106.72a25.72,25.72,0,0,1-8.64,5.2,31.37,31.37,0,0,1-11.84,2.32,37,37,0,0,1-15.28-3,31.8,31.8,0,0,1-11.52-8.64A39.93,39.93,0,0,1,2.56,89.28,53.8,53.8,0,0,1,0,72.16a51.31,51.31,0,0,1,2.72-17A39.68,39.68,0,0,1,10.4,41.76,34,34,0,0,1,36.64,30.08a29.32,29.32,0,0,1,13,2.64,30,30,0,0,1,7.76,5,.91.91,0,0,0,1,.32.73.73,0,0,0,.48-.8V.8a.71.71,0,0,1,.8-.8h17.6a.71.71,0,0,1,.8.8v111a.71.71,0,0,1-.8.8H62.08c-1,0-1.5-.26-1.6-.8l-1.6-5.12a.68.68,0,0,0-.72-.48A1.46,1.46,0,0,0,57.12,106.72ZM19.2,72.16q0,10.89,5.2,17.68t15.44,6.8q9.6,0,14.32-6.8t4.72-17.68q0-12-4.72-18.24T39.84,47.68q-10.25,0-15.44,6.8T19.2,72.16Z"
      />
      <path
        fill={fill ?? 'white'}
        d="M170.24,78.72c-.22.54-.48.8-.8.8H113.76a.71.71,0,0,0-.8.8,14.9,14.9,0,0,0,1.36,6.32,15.18,15.18,0,0,0,3.92,5.2,19,19,0,0,0,6,3.52A22,22,0,0,0,132,96.64a26.56,26.56,0,0,0,9.36-1.52,11,11,0,0,0,6.16-5.68,1.25,1.25,0,0,1,1.28-.8h18.72c.74,0,1,.27.8.8a29,29,0,0,1-12.64,18.24q-9.93,6.57-23.68,6.56a40.67,40.67,0,0,1-16-3,34.75,34.75,0,0,1-12.32-8.64,39.21,39.21,0,0,1-7.92-13.28A49.58,49.58,0,0,1,93,72.16,50.33,50.33,0,0,1,95.76,55a38.27,38.27,0,0,1,7.92-13.28A35.32,35.32,0,0,1,116,33.12a43.63,43.63,0,0,1,32,0,35,35,0,0,1,12.32,8.64,39.51,39.51,0,0,1,7.92,13.36,50.05,50.05,0,0,1,2.8,17,22.77,22.77,0,0,1-.24,3.76C170.64,76.83,170.45,77.76,170.24,78.72Zm-20.32-15.2a.57.57,0,0,0,.64-.64,13.82,13.82,0,0,0-1-5,15.34,15.34,0,0,0-3.28-5,16.7,16.7,0,0,0-5.76-3.76A22.42,22.42,0,0,0,132,47.68a21.53,21.53,0,0,0-8.4,1.52,20.42,20.42,0,0,0-6,3.76,14.35,14.35,0,0,0-3.52,4.88,12.84,12.84,0,0,0-1.12,5,.56.56,0,0,0,.64.64Z"
      />
      <path
        fill={fill ?? 'white'}
        d="M252.32,87.52q0,13-9.52,19.84t-26,6.88a47.25,47.25,0,0,1-15.76-2.4,36,36,0,0,1-11.44-6.32,25.66,25.66,0,0,1-7-9,24.73,24.73,0,0,1-2.32-10.48.71.71,0,0,1,.8-.8h18.4a.71.71,0,0,1,.8.8c0,2.56,1.3,5,3.92,7.2s6.8,3.36,12.56,3.36a47.88,47.88,0,0,0,5.44-.32A20,20,0,0,0,227.52,95a10.24,10.24,0,0,0,4-2.8,7,7,0,0,0,1.6-4.72,5.14,5.14,0,0,0-1.6-4,10.32,10.32,0,0,0-4.08-2.16,27.44,27.44,0,0,0-5.36-1c-1.92-.16-3.68-.34-5.28-.56q-6.24-.63-12.48-1.76A40.61,40.61,0,0,1,193,74.4a21.25,21.25,0,0,1-8.16-7.2q-3.12-4.64-3.12-12.16a20.61,20.61,0,0,1,2.39-9.68,24,24,0,0,1,6.79-7.92,35,35,0,0,1,10.53-5.36,44.07,44.07,0,0,1,13.65-2,52.93,52.93,0,0,1,14,1.76,31.33,31.33,0,0,1,11,5.36,25.44,25.44,0,0,1,7.26,8.88,26.86,26.86,0,0,1,2.64,12.16c0,.54-.21.8-.64.8H231.67a.7.7,0,0,1-.79-.8,9.32,9.32,0,0,0-.8-3.52,9,9,0,0,0-2.64-3.44,15.81,15.81,0,0,0-4.88-2.56,23,23,0,0,0-7.36-1q-7.2,0-10.72,2.08T201,55a4.22,4.22,0,0,0,1.2,3.12,8.48,8.48,0,0,0,3.2,1.92,29.44,29.44,0,0,0,4.56,1.2q2.55.48,5.28.8,5.28.64,11.76,1.52A44.75,44.75,0,0,1,239,67a24.86,24.86,0,0,1,9.44,7.28Q252.32,79,252.32,87.52Z"
      />
      <path
        fill={fill ?? 'white'}
        d="M288.32,37.6A25.88,25.88,0,0,1,297,32.4a31.52,31.52,0,0,1,11.84-2.32,37.19,37.19,0,0,1,15.28,3,32,32,0,0,1,11.52,8.64,40.11,40.11,0,0,1,7.28,13.36,54.33,54.33,0,0,1,2.56,17,51.07,51.07,0,0,1-2.72,17.12A40,40,0,0,1,335,102.56a34.37,34.37,0,0,1-11.68,8.64,34.88,34.88,0,0,1-14.56,3,29.29,29.29,0,0,1-13-2.64,29.8,29.8,0,0,1-7.76-5,.92.92,0,0,0-1-.32.75.75,0,0,0-.48.8v36.48a.71.71,0,0,1-.8.8h-17.6a.71.71,0,0,1-.8-.8v-111a.71.71,0,0,1,.8-.8h15.2c1,0,1.49.27,1.6.8l1.6,5.12a.67.67,0,0,0,.72.48A1.46,1.46,0,0,0,288.32,37.6Zm37.92,34.56q0-10.87-5.2-17.68t-15.44-6.8q-9.6,0-14.32,6.8t-4.72,17.68q0,12,4.72,18.24t14.32,6.24q10.23,0,15.44-6.8T326.24,72.16Z"
      />
      <path
        fill={fill ?? 'white'}
        d="M411.52,105a35,35,0,0,1-9.44,6.24q-6.24,3-15.84,3a45,45,0,0,1-14.16-2,27.48,27.48,0,0,1-9.84-5.44,21.86,21.86,0,0,1-5.76-8.16,26.67,26.67,0,0,1-1.92-10.16,23.41,23.41,0,0,1,2.16-9.84,23.16,23.16,0,0,1,6.56-8.32,32.69,32.69,0,0,1,11-5.68,51.14,51.14,0,0,1,15.36-2.08h15a5.09,5.09,0,0,0,3.6-1.12,3.25,3.25,0,0,0,1.2-2.4,16.79,16.79,0,0,0-.4-3.2A8.13,8.13,0,0,0,407,52.08a13.13,13.13,0,0,0-5-3.12,27,27,0,0,0-9.28-1.28,27.47,27.47,0,0,0-7.6.88,14.32,14.32,0,0,0-4.8,2.32,8.94,8.94,0,0,0-2.72,3.36,11.49,11.49,0,0,0-1,4,.71.71,0,0,1-.8.8H358.24a.7.7,0,0,1-.8-.8,25.34,25.34,0,0,1,2.88-12.32,26.55,26.55,0,0,1,7.68-8.8,34.2,34.2,0,0,1,11.2-5.28,50.53,50.53,0,0,1,13.44-1.76q16.64,0,26.32,7.68t9.68,22.72v31a4.65,4.65,0,0,0,1.6,3.76,6.17,6.17,0,0,0,4.16,1.36h2.4a.71.71,0,0,1,.8.8v14.4a.71.71,0,0,1-.8.8h-8.16q-6.24,0-9.84-2.16A18.07,18.07,0,0,1,413,105Q412.31,104.17,411.52,105Zm-25.28-8.32A31.2,31.2,0,0,0,396.88,95a22,22,0,0,0,7.2-4.08,15.43,15.43,0,0,0,4.08-5.52,15.58,15.58,0,0,0,1.28-6.08.71.71,0,0,0-.8-.8H390.4a31.26,31.26,0,0,0-8.56,1,15.18,15.18,0,0,0-5.12,2.4,6.68,6.68,0,0,0-2.4,3.2,10.89,10.89,0,0,0-.56,3.36,7.39,7.39,0,0,0,.56,2.72,6.54,6.54,0,0,0,2,2.64,11.71,11.71,0,0,0,3.84,2A19.3,19.3,0,0,0,386.24,96.64Z"
      />
      <path
        fill={fill ?? 'white'}
        d="M482.71,30.08q15.36,0,25.28,7.6a31,31,0,0,1,12,20.56c.11.54-.16.8-.8.8H501.11a1.13,1.13,0,0,1-1.12-.8A18,18,0,0,0,493.83,50q-4.08-3-11.12-3a18.83,18.83,0,0,0-8.8,1.92,17.88,17.88,0,0,0-6.16,5.28,23.11,23.11,0,0,0-3.68,8,41.6,41.6,0,0,0,0,19.84,23,23,0,0,0,3.68,8,17.88,17.88,0,0,0,6.16,5.28,18.83,18.83,0,0,0,8.8,1.92q8,0,11.76-3a19,19,0,0,0,5.84-8.24,1.15,1.15,0,0,1,1.12-.8h18.08c.64,0,.91.27.8.8q-2.55,13.77-12.48,21t-25.12,7.2a42.89,42.89,0,0,1-16.7-3,32.78,32.78,0,0,1-12.18-8.64,38.9,38.9,0,0,1-7.54-13.28,52.94,52.94,0,0,1-2.62-17.12A53.75,53.75,0,0,1,446.29,55a38,38,0,0,1,7.54-13.28A33.29,33.29,0,0,1,466,33.12,43.07,43.07,0,0,1,482.71,30.08Z"
      />
      <path
        fill={fill ?? 'white'}
        d="M607.35,78.72c-.21.54-.48.8-.8.8H550.87a.71.71,0,0,0-.8.8,15,15,0,0,0,1.36,6.32,15.44,15.44,0,0,0,3.92,5.2,19.23,19.23,0,0,0,6,3.52,22,22,0,0,0,7.76,1.28,26.56,26.56,0,0,0,9.36-1.52,11,11,0,0,0,6.16-5.68,1.25,1.25,0,0,1,1.28-.8h18.72c.75,0,1,.27.8.8a28.92,28.92,0,0,1-12.64,18.24q-9.91,6.57-23.68,6.56a40.71,40.71,0,0,1-16-3,34.84,34.84,0,0,1-12.32-8.64,39.54,39.54,0,0,1-7.92-13.28,49.82,49.82,0,0,1-2.8-17.12,50.58,50.58,0,0,1,2.8-17.2,38.58,38.58,0,0,1,7.92-13.28,35.41,35.41,0,0,1,12.32-8.56,43.63,43.63,0,0,1,32,0,34.87,34.87,0,0,1,12.32,8.64,39.19,39.19,0,0,1,7.92,13.36,49.81,49.81,0,0,1,2.8,17,22.77,22.77,0,0,1-.24,3.76C607.75,76.83,607.57,77.76,607.35,78.72ZM587,63.52a.56.56,0,0,0,.64-.64,13.61,13.61,0,0,0-1-5,15,15,0,0,0-3.28-5,16.51,16.51,0,0,0-5.76-3.76,22.34,22.34,0,0,0-8.48-1.44,21.53,21.53,0,0,0-8.4,1.52,20.42,20.42,0,0,0-6,3.76,14.48,14.48,0,0,0-3.52,4.88,13,13,0,0,0-1.12,5,.57.57,0,0,0,.64.64Z"
      />
      <path
        fill={fill ?? 'white'}
        d="M130.16,282.72a1.46,1.46,0,0,0-1-.48.67.67,0,0,0-.72.48l-1.6,5.12c-.11.54-.64.8-1.6.8H110a.7.7,0,0,1-.8-.8v-111a.71.71,0,0,1,.8-.8h17.6a.71.71,0,0,1,.8.8v36.48a.73.73,0,0,0,.48.8.91.91,0,0,0,1-.32,29.8,29.8,0,0,1,7.76-5,29.29,29.29,0,0,1,13-2.64,35,35,0,0,1,14.56,3,34.5,34.5,0,0,1,11.68,8.64,40,40,0,0,1,7.68,13.36,51.35,51.35,0,0,1,2.72,17,54.09,54.09,0,0,1-2.56,17.12,40.11,40.11,0,0,1-7.28,13.28,31.77,31.77,0,0,1-11.52,8.64,37,37,0,0,1-15.28,3,31.33,31.33,0,0,1-11.84-2.32A25.72,25.72,0,0,1,130.16,282.72Zm37.92-34.56q0-10.87-5.2-17.68t-15.44-6.8q-9.6,0-14.32,6.24t-4.72,18.24q0,10.89,4.72,17.68t14.32,6.8q10.23,0,15.44-6.8T168.08,248.16Z"
      />
      <path
        fill={fill ?? 'white'}
        d="M274.48,254.72c-.22.54-.48.8-.8.8H218a.71.71,0,0,0-.8.8,14.9,14.9,0,0,0,1.36,6.32,15.18,15.18,0,0,0,3.92,5.2,18.82,18.82,0,0,0,6,3.52,22,22,0,0,0,7.76,1.28,26.56,26.56,0,0,0,9.36-1.52,11,11,0,0,0,6.16-5.68,1.25,1.25,0,0,1,1.28-.8h18.72c.74,0,1,.27.8.8a29,29,0,0,1-12.64,18.24q-9.93,6.57-23.68,6.56a40.67,40.67,0,0,1-16-3,34.62,34.62,0,0,1-12.32-8.64A39.21,39.21,0,0,1,200,265.28a49.58,49.58,0,0,1-2.8-17.12A50.33,50.33,0,0,1,200,231a38.27,38.27,0,0,1,7.92-13.28,35.32,35.32,0,0,1,12.32-8.56,43.63,43.63,0,0,1,32,0,35,35,0,0,1,12.32,8.64,39.51,39.51,0,0,1,7.92,13.36,50.09,50.09,0,0,1,2.8,17,22.77,22.77,0,0,1-.24,3.76C274.88,252.83,274.69,253.76,274.48,254.72Zm-20.32-15.2a.57.57,0,0,0,.64-.64,13.82,13.82,0,0,0-1-5,15.34,15.34,0,0,0-3.28-5,16.84,16.84,0,0,0-5.76-3.76,22.42,22.42,0,0,0-8.48-1.44,21.53,21.53,0,0,0-8.4,1.52,20.42,20.42,0,0,0-6,3.76,14.35,14.35,0,0,0-3.52,4.88,12.84,12.84,0,0,0-1.12,5,.56.56,0,0,0,.64.64Z"
      />
      <path
        fill={fill ?? 'white'}
        d="M326.8,224.48a20.2,20.2,0,0,0-8,1.44,14.69,14.69,0,0,0-5.52,4,15.94,15.94,0,0,0-3.12,5.84,24.13,24.13,0,0,0-1,6.8v45.28a.71.71,0,0,1-.8.8H290.64c-.43,0-.64-.26-.64-.8V208.48c0-.53.21-.8.64-.8H306a1.49,1.49,0,0,1,1.6,1l1.6,4.8c.42.75.9.86,1.44.32a28.37,28.37,0,0,1,7.92-5.68,22.84,22.84,0,0,1,9.84-2h8.8a.71.71,0,0,1,.8.8v16.8a.71.71,0,0,1-.8.8Z"
      />
      <path
        fill={fill ?? 'white'}
        d="M349.36,288.64a.7.7,0,0,1-.8-.8v-111a.71.71,0,0,1,.8-.8H367a.71.71,0,0,1,.8.8v111a.71.71,0,0,1-.8.8Z"
      />
      <path
        fill={fill ?? 'white'}
        d="M387.59,197.12a.71.71,0,0,1-.8-.8V176.8a.71.71,0,0,1,.8-.8h19.2a.71.71,0,0,1,.8.8v19.52a.7.7,0,0,1-.8.8Zm.8,91.52a.71.71,0,0,1-.8-.8V208.48a.71.71,0,0,1,.8-.8H406a.7.7,0,0,1,.79.8v79.36a.7.7,0,0,1-.79.8Z"
      />
      <path
        fill={fill ?? 'white'}
        d="M447.59,213.6a12.75,12.75,0,0,1,3.28-2.8,29.85,29.85,0,0,1,4.8-2.4,37.63,37.63,0,0,1,5.52-1.68,25.59,25.59,0,0,1,5.44-.64q9.45,0,15.92,3.12a27.54,27.54,0,0,1,10.4,8.4,34.39,34.39,0,0,1,5.68,12.16,57.61,57.61,0,0,1,1.76,14.4v43.68a.7.7,0,0,1-.8.8H482a.71.71,0,0,1-.8-.8V244.16a28.54,28.54,0,0,0-1-7.2,19.59,19.59,0,0,0-3.12-6.64,16.67,16.67,0,0,0-5.52-4.8,16.85,16.85,0,0,0-8.16-1.84,18.56,18.56,0,0,0-8,1.6,15.8,15.8,0,0,0-5.52,4.24,16.5,16.5,0,0,0-3.12,6.08,25.85,25.85,0,0,0-1,7v45.28a.7.7,0,0,1-.8.8h-17.6a.71.71,0,0,1-.8-.8V208.48a.71.71,0,0,1,.8-.8h15.2c.86,0,1.34.27,1.44.8l1.76,5a1,1,0,0,0,.72.64C446.93,214.19,447.27,214,447.59,213.6Z"
      />
    </svg>
  );
}

const MemoDespaceIcon = React.memo(DespaceIcon);
export default MemoDespaceIcon;
