import React from 'react';
import { AnimatePresence } from 'framer-motion';
import { BrowserRouter as Router, Switch, Route, useLocation } from 'react-router-dom';
import { routes } from './routes';
import { ComponentRoute } from '../interfaces';
import { featuredHackathons } from '../pages/FeaturedHackathonPage/FeaturedHackathons';

const HomePage = React.lazy(() => import('../pages/HomePage/HomePage'));
const FeaturedHackathons = React.lazy(
  () => import('../pages/FeaturedHackathonPage/FeaturedHackathonPage'),
);
const NotFoundPage = React.lazy(() => import('../pages/NotFoundPage/NotFoundPage'));

const RouteWithSubRoutes = (route: ComponentRoute) => {
  const { path } = route;
  return (
    <Route
      path={path}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render={(props: any) => <route.component {...props} routes={route?.routes} />}
    />
  );
};

const AppRoutes: React.FC = () => {
  const location = useLocation();
  return (
    <AnimatePresence>
      <Switch location={location} key={location.pathname}>
        {routes.map((route, i) => (
          <RouteWithSubRoutes key={i} {...route} />
        ))}
        <Route component={NotFoundPage} />
      </Switch>
    </AnimatePresence>
  );
};

export const AppRouter: React.FC = () => {
  return (
    <Router basename="/hackathons">
      <Switch>
        <Route exact path="/" component={HomePage} />
        {featuredHackathons.map((item) => (
          <Route
            key={item.url}
            exact
            path={`/featured/${item.url}`}
            render={() => <FeaturedHackathons {...item.props} />}
          />
        ))}
        <Route path="*">
          <AppRoutes />
        </Route>
      </Switch>
    </Router>
  );
};
