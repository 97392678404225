import React, { Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import TagManager from 'react-gtm-module';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import { Global } from '@emotion/react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { GlobalStyle } from './design-system/theming/styles';
import { lightTheme } from './design-system/theming/light';
import { AppRouter } from './router';
import { Loading } from './design-system/atoms/Loading';

const App: React.FC = () => {
  const [theme] = React.useState(lightTheme);
  const tagManagerArgs = {
    gtmId: 'GTM-TKF7TZN',
  };

  React.useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  });

  return (
    <Suspense fallback={<Loading theme={lightTheme} />}>
      <HelmetProvider>
        <Global styles={GlobalStyle(theme)} />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <AppRouter />
            </ThemeProvider>
          </StyledEngineProvider>
        </LocalizationProvider>
      </HelmetProvider>
    </Suspense>
  );
};

export default App;
