import React from 'react';
import styled from '@emotion/styled';
import { Theme, useTheme } from '@mui/material';

export interface LoadingProps {
  /**
   * Show size
   */
  size?: 'xs' | 'md' | 'lg';
  hasContainer?: boolean;
  theme?: Theme;
}

interface StyledProps {
  theme: Theme;
}

const Styledloading = styled.div<StyledProps>`
  display: inline-block;
  background-color: transparent;

  &.xs {
    width: ${({ theme }) => theme.spacing(3)};
    height: ${({ theme }) => theme.spacing(3)};
  }

  &.md {
    width: ${({ theme }) => theme.spacing(6)};
    height: ${({ theme }) => theme.spacing(6)};
  }

  &.lg {
    width: ${({ theme }) => theme.spacing(8)};
    height: ${({ theme }) => theme.spacing(8)};
  }

  .circle-border {
    width: 100%;
    height: 100%;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: ${({ theme }) => theme.palette.primary.main};
    background: ${({ theme }) =>
      `linear-gradient(0deg, ${theme.palette.primary.main} 33%, ${theme.palette.purple.main} 100%)`};
    animation: spin 0.8s linear 0s infinite;
  }

  .circle-core {
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.palette.grey[100]};
    border-radius: 50%;
  }

  @keyframes spin {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;

const CenterContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;
  justify-content: center;
`;

export const Loading: React.FC<LoadingProps> = ({
  size = 'lg',
  hasContainer = true,
  theme,
  ...rest
}) => {
  const innerTheme = useTheme();

  const Spinner = () => (
    <Styledloading className={size} {...rest} theme={theme || innerTheme}>
      <div className="circle-border">
        <div className="circle-core" />
      </div>
    </Styledloading>
  );

  return (
    <>
      {!hasContainer && <Spinner />}
      {hasContainer && (
        <CenterContainer>
          <Spinner />
        </CenterContainer>
      )}
    </>
  );
};
