import * as React from 'react';

function TezosUkraineIcon({
  fill,
  width,
  height,
  stroke,
  shapeRendering,
  textRendering,
  imageRendering,
  fillRule,
  clipRule,
  opacity,
  ...rest
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      version="1.0"
      width="75px"
      height="88px"
      shapeRendering="eometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
      viewBox="0 0 1048.000000 1240.000000"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      fill="none"
      {...rest}
    >
      <g>
        <path
          opacity="0.008"
          fill="#fefffe"
          d="M 6.5,-0.5 C 6.83333,-0.5 7.16667,-0.5 7.5,-0.5C 7.5,-0.166667 7.5,0.166667 7.5,0.5C 7.16667,0.166667 6.83333,-0.166667 6.5,-0.5 Z"
        />
      </g>
      <g>
        <path
          opacity="0.972"
          fill="#fefffe"
          d="M 9.5,-0.5 C 352.833,-0.5 696.167,-0.5 1039.5,-0.5C 1044.17,2.17831 1046.84,6.17831 1047.5,11.5C 1047.5,256.167 1047.5,500.833 1047.5,745.5C 1033.47,914.447 958.136,1048.28 821.5,1147C 741.283,1201.8 652.617,1232.63 555.5,1239.5C 534.5,1239.5 513.5,1239.5 492.5,1239.5C 333.135,1226.58 203.968,1157.91 105,1033.5C 45.1845,952.579 10.0178,861.912 -0.5,761.5C -0.5,510.833 -0.5,260.167 -0.5,9.5C 0.833333,9.16667 0.833333,8.83333 -0.5,8.5C -0.5,7.83333 -0.5,7.16667 -0.5,6.5C 0.924541,8.03105 1.59121,7.69772 1.5,5.5C 3.16667,4.5 4.5,3.16667 5.5,1.5C 6.49162,1.67158 7.15829,1.33825 7.5,0.5C 8.49162,0.67158 9.15829,0.338246 9.5,-0.5 Z M 68.5,44.5 C 361.333,45.8119 654.167,45.8119 947,44.5C 959.206,44.3992 971.373,44.8992 983.5,46C 993.658,48.496 999.158,54.996 1000,65.5C 1000.67,283.5 1000.67,501.5 1000,719.5C 995.503,862.966 940.003,982.133 833.5,1077C 717.668,1172.05 585.334,1208.05 436.5,1185C 305.441,1157.85 202.275,1089.35 127,979.5C 80.8717,909.789 54.2051,833.122 47,749.5C 45.3534,521.509 45.0201,293.509 46,65.5C 47.7014,52.2402 55.2014,45.2402 68.5,44.5 Z"
        />
      </g>
      <g>
        <path
          opacity="0.004"
          fill="#fefffe"
          d="M 5.5,1.5 C 4.83333,0.166667 4.83333,0.166667 5.5,1.5 Z"
        />
      </g>
      <g>
        <path
          opacity="0.004"
          fill="#fefffe"
          d="M 2.5,1.5 C 3.83333,2.16667 3.83333,2.16667 2.5,1.5 Z"
        />
      </g>
      <g>
        <path
          opacity="0.004"
          fill="#fefffe"
          d="M 2.5,2.5 C 1.83333,3.16667 1.83333,3.16667 2.5,2.5 Z"
        />
      </g>
      <g>
        <path
          opacity="0.004"
          fill="#fefffe"
          d="M 1.5,5.5 C 0.833333,4.16667 0.833333,4.16667 1.5,5.5 Z"
        />
      </g>
      <g>
        <path
          opacity="0.979"
          fill="#fefffe"
          d="M 401.5,113.5 C 403.496,168.823 404.163,224.49 403.5,280.5C 531.167,280.333 658.834,280.5 786.5,281C 788.286,281.785 789.786,282.951 791,284.5C 791.667,293.5 791.667,302.5 791,311.5C 722.512,382.955 653.678,454.122 584.5,525C 635.101,545.118 681.434,572.118 723.5,606C 759.31,635.8 785.81,672.3 803,715.5C 823.145,782.671 815.145,846.337 779,906.5C 750.057,949.577 710.557,978.411 660.5,993C 626.156,1002.5 591.156,1007.33 555.5,1007.5C 512.11,1008.21 470.11,1001.05 429.5,986C 396.294,972.459 371.794,949.625 356,917.5C 345.085,892.604 343.752,867.27 352,841.5C 367.89,813.809 391.723,802.643 423.5,808C 453.213,815.91 468.213,835.41 468.5,866.5C 468.107,889.138 457.774,905.305 437.5,915C 436.483,916.049 436.316,917.216 437,918.5C 441.111,927.61 447.611,934.444 456.5,939C 473.162,947.583 490.829,952.917 509.5,955C 533.136,957.813 556.803,958.147 580.5,956C 650.849,944.318 693.016,903.485 707,833.5C 717.787,787.973 710.121,745.973 684,707.5C 653.749,665.261 614.582,634.095 566.5,614C 538.502,602.251 509.502,594.084 479.5,589.5C 479.333,572.497 479.5,555.497 480,538.5C 545.864,470.97 611.364,403.137 676.5,335C 585.501,334.5 494.501,334.333 403.5,334.5C 403.333,430.834 403.5,527.167 404,623.5C 404.621,641.32 409.288,657.987 418,673.5C 435.677,695.885 458.177,702.385 485.5,693C 496.84,689.163 507.506,683.996 517.5,677.5C 518.666,688.485 518.832,699.485 518,710.5C 516.38,716.896 512.88,722.062 507.5,726C 478.123,745.009 446.123,751.342 411.5,745C 359.862,736.025 326.362,706.525 311,656.5C 307.604,644.393 305.604,632.059 305,619.5C 304.5,524.501 304.333,429.501 304.5,334.5C 266.833,334.5 229.167,334.5 191.5,334.5C 191.333,317.497 191.5,300.497 192,283.5C 192.5,282.333 193.333,281.5 194.5,281C 231.165,280.5 267.832,280.333 304.5,280.5C 304.944,250.134 304.444,219.801 303,189.5C 298.366,176.539 289.2,169.039 275.5,167C 271.179,166.501 266.846,166.334 262.5,166.5C 262.5,158.833 262.5,151.167 262.5,143.5C 309.058,133.99 355.391,123.99 401.5,113.5 Z"
        />
      </g>
    </svg>
  );
}

const MemoTezosUkraineIcon = React.memo(TezosUkraineIcon);
export default MemoTezosUkraineIcon;
