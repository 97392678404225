import * as React from 'react';
import { ComponentRoute } from '../interfaces';

export const routes: ComponentRoute[] = [
  {
    path: '/organizer',
    component: React.lazy(() => import('../pages/OrganizerPage/OrganizerPage')),
  },
  {
    path: '/funding-application',
    component: React.lazy(() => import('../pages/FundingApplicationPage/FundingApplicationPage')),
  },
  {
    path: '/collaboration-application',
    component: React.lazy(
      () => import('../pages/PartnershipApplicationPage/PartnershipApplicationPage'),
    ),
  },
  {
    path: '/participant',
    component: React.lazy(() => import('../pages/ParticipantPage/ParticipantPage')),
  },
  {
    path: '/upcoming',
    component: React.lazy(() => import('../pages/UpcomingHackathonPage/UpcomingHackathonPage')),
  },
  {
    path: '/hackathon-application',
    component: React.lazy(() => import('../pages/ListHackathonPage/ListHackathonPage')),
  },
  {
    path: '/success',
    component: React.lazy(() => import('../pages/SuccessPage/SuccessPage')),
  },
];
