import { createTheme, PaletteOptions } from '@mui/material';
import { TypographyStyleOptions } from '@mui/material/styles/createTypography';
import { GlobalStyle } from './styles';

declare module '@mui/material/styles' {
  interface Palette {
    purple: {
      main: string;
    };
    black: {
      main: string;
      dark: string;
      light: string;
    };
  }
  interface PaletteOptions {
    purple: {
      main: string;
    };
    black: {
      main: string;
      dark: string;
      light: string;
    };
  }
  interface PaletteColor {
    lighter?: string;
    lightest?: string;
  }
  interface SimplePaletteColorOptions {
    lighter?: string;
    lightest?: string;
  }

  interface TypographyVariants {
    poster: React.CSSProperties;
    poster2: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    poster?: TypographyStyleOptions;
    poster2?: TypographyStyleOptions;
  }
}
const lightThemePalette: PaletteOptions = {
  primary: {
    dark: '#003ee0',
    main: '#0f62ff',
    light: '#408dff',
    contrastText: '#FFFFFF',
  },
  secondary: {
    dark: '#bedfff',
    main: '#f6f9ff',
    light: '#fbfcff',
    contrastText: '#1e1e1e',
  },
  success: {
    main: '#62c796',
  },
  warning: {
    main: '#f1d43c',
  },
  error: {
    main: '#ff5934',
  },
  grey: {
    900: '#1E1E1E',
    800: '#6e6e81',
    700: '#f5f7fa',
    600: '#f7f9fb',
    200: '#1c1e2130',
    100: '#FFFFFF',
  },
  purple: {
    main: '#9f329f',
  },
  black: {
    main: '#233456',
    light: '#233456',
    dark: '#272d3d',
  },
  text: {
    primary: '#1e1e1e',
    secondary: '#6e6e81',
    disabled: '#f7f9fb',
  },
  background: {
    default: '#FFFFFF',
    paper: '#FFFFFF',
  },
  action: {
    disabled: '#f7f9fb',
    disabledBackground: '#bedfff',
  },
  tonalOffset: 0.05,
  mode: 'light',
};

export const lightTheme = createTheme({
  palette: lightThemePalette,
  typography: {
    fontWeightLight: 300,
    fontWeightMedium: 500,
    h1: {
      fontFamily: 'GT-Eesti-Regular',
      fontWeight: 300,
      fontSize: '3.75rem',
      lineHeight: '1.13',
      '@media (max-width:600px)': {
        fontSize: '2.5rem',
      },
    },
    h2: {
      fontFamily: 'GT-Eesti-Regular',
      fontWeight: 300,
      fontSize: '2.5rem',
      lineHeight: '1.13',
    },
    h3: {
      fontFamily: 'GT-Eesti-Regular',
      fontWeight: 300,
      fontSize: '2.125rem',
      lineHeight: '1.3',
    },
    h4: {
      fontFamily: 'GT-Eesti-Regular',
      fontWeight: 300,
      fontSize: '1.625rem',
      lineHeight: '1.5',
    },
    h5: {
      fontFamily: 'GT-Eesti-Regular',
      fontWeight: 300,
      fontSize: '1.625rem',
      lineHeight: '1.15',
    },
    h6: {
      fontFamily: 'GT-Eesti-Regular',
      fontWeight: 300,
      fontSize: '1rem',
      lineHeight: '1.5',
    },
    subtitle1: {
      fontFamily: 'GT-Eesti-Regular',
      fontWeight: 300,
      fontSize: '0.875rem',
      lineHeight: '1.2',
    },
    subtitle2: {
      fontWeight: 300,
      fontSize: '0.75rem',
      lineHeight: '1.2',
    },
    body1: {
      fontWeight: 300,
      fontSize: '1rem',
      lineHeight: '1.38',
    },
    body2: {
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: '1.38',
    },
    poster: {
      fontFamily: 'GT-Eesti-Bold',
      fontSize: '7.75rem',
      '@media (max-width:600px)': {
        fontSize: '3.75rem',
      },
    },
    poster2: {
      fontFamily: 'GT-Eesti-Bold',
      fontSize: '3.25rem',
      '@media (max-width:600px)': {
        fontSize: '2.5rem',
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'filled',
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        underline: {
          '&:before': {
            display: 'none',
          },
          '&:after': {
            display: 'none',
          },
          borderRadius: 2,
        },
        root: {
          paddingTop: 0,
          marginTop: 8,
          backgroundColor: '#f5f7fa',
          '&:hover, &:focus, &.Mui-focused': {
            backgroundColor: '#F7F9FB',
          },
        },
        adornedStart: {
          ':root': {
            marginTop: 0,
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'filled',
      },
      styleOverrides: {
        select: {
          '&:focus': {
            backgroundColor: '#F7F9FB',
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          '&.MuiPickersDay-root.Mui-disabled': {
            color: '#6e6e81',
          },
          '&.MuiPickersDay-root.MuiPickersDay-today': {
            backgroundColor: '#bedfff',
            borderColor: '#0f62ff',
          },
        },
      },
    },
  },
});

export const light = GlobalStyle(lightTheme);
